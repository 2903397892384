<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目报表</el-breadcrumb-item>
      <el-breadcrumb-item>项目数据</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 用户列表卡片区 -->
    <el-card class="box-card" style="overflow-x: scroll;">

      <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">
        <el-form-item label="项目">
          <el-select
              v-model="queryMap.id"
              placeholder="请选择项目">
            <el-option
                v-for="organ in userOptions"
                :key="organ.id"
                :label="organ.name"
                :value="organ.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报表类型">
          <el-select
              clearable
              v-model="queryMap.status"
              placeholder="请选择报表类型"
              @change="reportTypeChange()"
          >
            <el-option
                v-for="organ in typeOptions"
                :key="organ.id"
                :label="organ.name"
                :value="organ.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间范围" v-if="picker_status">
          <el-date-picker
              v-model="queryMap.value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left:50px;">
          <el-button type="primary" icon="el-icon-search" @click="showList">查询</el-button>
        </el-form-item>
      </el-form>
      <!--      <div style="width: 80%; height: 1000px;">-->
      <!--        <iframe-->
      <!--            src="http://165.154.172.167:81"-->
      <!--            width="100%"-->
      <!--            height="100%"-->
      <!--            frameborder="0"-->
      <!--            scrolling="no">-->
      <!--        </iframe>-->
      <!--      </div>-->

      <div v-show="t2_show"
           style="padding-top: 10px;padding-bottom: 10px; margin-top: 30px; display:flex;justify-content: center;">
        <el-row>
          <el-col style="background-color: white;  width: 820px; padding: 20px 20px 20px 20px">
            <el-divider></el-divider>
            <h1>项目工时统计 (部署细分): </h1>

            <div ref="projectTime_deploy" id="projectTime_deploy"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>

            <el-table
                :data="projectTime_deployData"
                size="mini"
                style="width: 901px; margin-top: 30px"
                border
            >
              <el-table-column
                  prop="project"
                  label="项目"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="部署1"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="部署2"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="部署3"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="部署4"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>


            <h1 style="margin-top: 70px">项目工时统计 (每日): </h1>
            <div ref="projectTime_day" id="projectTime_day"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>
            <el-table
                :data="projectTime_dayData"
                size="mini"
                border
                style="width: 901px; margin-top: 30px"
            >
              <el-table-column
                  prop="project"
                  label="项目"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="2024/02/07"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="2024/02/08"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="2024/02/09"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="2024/02/10"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>

            <h3 style="margin-top: 70px">部署工时统计 (项目细分): </h3>
            <div ref="deploytime_project" id="deploytime_project"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>
            <el-table
                :data="deploytime_projectData"
                size="mini"
                border
                style="width: 901px; margin-top: 30px"
            >
              <el-table-column
                  prop="project"
                  label="部署"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="项目1"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="项目2"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="项目3"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="项目4"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>

            <h1 style="margin-top: 70px">部署工时统计 (每日): </h1>
            <div ref="deploytime_day" id="deploytime_day"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>
            <el-table
                :data="deploytime_dayData"
                size="mini"
                border
                style="width: 901px; margin-top: 30px"
            >
              <el-table-column
                  prop="project"
                  label="项目"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="2024/02/07"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="2024/02/08"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="2024/02/09"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="2024/02/10"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>

            <h1 style="margin-top: 70px">担当者工时统计 (项目细分): </h1>
            <div ref="usertime_project" id="usertime_project"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>
            <el-table
                :data="usertime_projectData"
                size="mini"
                border
                style=" width: 901px;margin-top:30px"
            >
              <el-table-column
                  prop="project"
                  label="担当者"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="项目1"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="项目2"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="项目3"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="项目4"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>

            <h1 style="margin-top: 70px">担当者工时统计 (每日): </h1>
            <div ref="usertime_day" id="usertime_day"
                 style="width:600px; height:300px;text-align: center;margin:auto;"></div>

            <el-table
                :data="usertime_dayData"
                size="mini"
                border
                style="width: 901px; margin-top: 30px"
            >
              <el-table-column
                  prop="project"
                  label="担当者"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="2024/02/07"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu2"
                  label="2024/02/08"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu3"
                  label="2024/02/09"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu4"
                  label="2024/02/10"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="heji"
                  label="总计"
                  width="150">
              </el-table-column>
            </el-table>

            <h1 style="margin-top: 70px">未投入工时担当者统计: </h1>

            <h4>总人数: 7</h4>

            <el-table
                :data="nouserData"
                size="mini"
                border
                style="width: 301px;"
            >
              <el-table-column
                  prop="no"
                  label="序号"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="bushu1"
                  label="姓名"
                  width="150">
              </el-table-column>
            </el-table>
            <el-divider></el-divider>
          </el-col>
        </el-row>

      </div>
      <div v-show="t3_show"
           style="padding-top: 10px; padding-bottom: 10px;margin-top: 30px; display:flex;justify-content: center;">
        <el-row :gutter="20">
          <el-col :span="22" :offset="1" style="background-color: white;  width: 820px">
            <el-divider></el-divider>
            <h1 style="margin-top: 50px">预定开始任务一览</h1>
            <el-table
                :data="tableData"
                size="mini"
                border
                style="width: 1401px"
            >
              <el-table-column
                  prop="no"
                  label="Ticket号"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="pname"
                  label="项目名称"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="jname"
                  label="阶段名称"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="type"
                  label="跟踪类型"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="title"
                  label="标题"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="状态"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="btype"
                  label="部署类型"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="dname"
                  label="担当者姓名"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="stime"
                  label="开始日期"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="jtime"
                  label="计划完成日期"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="ttime"
                  label="调整完成日期"
                  width="150">
              </el-table-column>
            </el-table>
            <el-divider></el-divider>
          </el-col>
        </el-row>
      </div>

      <div v-show="t32_show"
           style="padding-top: 10px; padding-bottom: 10px;margin-top: 30px; display:flex;justify-content: center;">
        <el-row :gutter="20">
          <el-col :span="22" :offset="1" style="background-color: white;  width: 820px">
            <el-divider></el-divider>
            <h1 style="margin-top: 50px">预定完成任务一览</h1>
            <el-table
                :data="tableData"
                size="mini"
                border
                style="width: 1401px"
            >
              <el-table-column
                  prop="no"
                  label="Ticket号"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="pname"
                  label="项目名称"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="jname"
                  label="阶段名称"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="type"
                  label="跟踪类型"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="title"
                  label="标题"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="状态"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="btype"
                  label="部署类型"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="dname"
                  label="担当者姓名"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="stime"
                  label="开始日期"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="jtime"
                  label="计划完成日期"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="ttime"
                  label="调整完成日期"
                  width="150">
              </el-table-column>
            </el-table>
            <el-divider></el-divider>

          </el-col>
        </el-row>


      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "projectData",
  data() {
    return {
      blinkInterval: null,
      picker_status: true,
      currentOpacity: 1,
      queryMap: {},
      addVisible: false,
      addMap: {},
      tableData: [
        {
          no: 1,
          pname: "项目1",
          jname: "阶段1",
          type: "Task",
          title: "标题",
          status: "测试状态",
          btype: "部署1",
          dname: "担当者1",
          stime: "2024-02-17",
          jtime: "2024-02-17",
          ttime: "2024-02-17"
        },
        {
          no: 1,
          pname: "项目1",
          jname: "阶段1",
          type: "Task",
          title: "标题",
          status: "测试状态",
          btype: "部署1",
          dname: "担当者1",
          stime: "2024-02-17",
          jtime: "2024-02-17",
          ttime: "2024-02-17"
        },
        {
          no: 1,
          pname: "项目1",
          jname: "阶段1",
          type: "Task",
          title: "标题",
          status: "测试状态",
          btype: "部署1",
          dname: "担当者1",
          stime: "2024-02-17",
          jtime: "2024-02-17",
          ttime: "2024-02-17"
        },
        {
          no: 1,
          pname: "项目1",
          jname: "阶段1",
          type: "Task",
          title: "标题",
          status: "测试状态",
          btype: "部署1",
          dname: "担当者1",
          stime: "2024-02-17",
          jtime: "2024-02-17",
          ttime: "2024-02-17"
        },
      ],
      projectTime_deployData: [
        {project: "项目1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "总计", bushu1: 40, bushu2: 80, bushu3: 60, bushu4: 4, heji: 120}
      ],
      userOptions: [
        {id: 1, name: "根项目1"},
        {id: 2, name: "根项目2"},
        {id: 3, name: "根项目3"},
        {id: 4, name: "根项目4"},
      ],
      typeOptions: [
        {id: 1, name: "组织工数统计"},
        {id: 2, name: "预定开始任务一览"},
        {id: 3, name: "预定完成任务一览"},
      ],
      projectTime_deploy_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['项目1', '项目2', '项目3', '项目4', '项目5', '项目6', '项目7']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '部署1',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '部署2',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '部署3',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '部署4',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          }
        ]
      },
      report_type: this.$route.query.report_type,
      projectTime_dayData: [
        {project: "项目1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "项目4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "总计", bushu1: 40, bushu2: 80, bushu3: 60, bushu4: 4, heji: 120},
      ],
      projectTime_day_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['项目1', '项目2', '项目3', '项目4', '项目5', '项目6', '项目7']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '2024/02/07',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '2024/02/08',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '2024/02/09',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '2024/02/10',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          }
        ]
      },
      deploytime_projectData: [
        {project: "部署1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署5", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署6", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署7", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "总计", bushu1: 70, bushu2: 140, bushu3: 105, bushu4: 7, heji: 272},
      ],
      deploytime_project_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['部署1', '部署2', '部署3', '部署4', '部署5', '部署6', '部署7']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '项目1',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '项目2',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '项目3',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '项目4',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          }
        ]
      },
      deploytime_dayData: [
        {project: "部署1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署5", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署6", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "部署7", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {project: "总计", bushu1: 70, bushu2: 140, bushu3: 105, bushu4: 7, heji: 272},
      ],
      deploytime_day_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['部署1', '部署2', '部署3', '部署4', '部署5', '部署6', '部署7']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '2024/02/07',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '2024/02/08',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '2024/02/09',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '2024/02/10',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          }
        ]
      },
      usertime_projectData: [
        {project: "担当者1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者5", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者6", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者7", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "总计", bushu1: 70, bushu2: 140, bushu3: 105, bushu4: 7, bushu5: 7, bushu6: 7, bushu7: 7, heji: 272},
      ],
      usertime_project_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['担当者1', '担当者2', '担当者3', '担当者4', '担当者5', '担当者6', '担当者7']
        },
        series: [
          {
            name: '项目1',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: '项目2',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '项目3',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '项目4',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [150, 212, 201, 154, 190, 330, 410]
          },
          {
            name: '项目5',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [820, 832, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
      usertime_dayData: [
        {project: "担当者1", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者2", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者3", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者4", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "担当者5", bushu1: 10, bushu2: 20, bushu3: 15, bushu4: 1, bushu5: 1, bushu6: 1, bushu7: 1, heji: 46},
        {project: "总计", bushu1: 70, bushu2: 140, bushu3: 105, bushu4: 7, bushu5: 7, bushu6: 7, bushu7: 7, heji: 272},
      ],
      usertime_day_option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['担当者1', '担当者2', '担当者3', '担当者4', '担当者5', '担当者6', '担当者7']
        },
        series: [
          {
            name: '2024/02/07',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: '2024/02/08',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '2024/02/09',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '2024/02/10',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [150, 212, 201, 154, 190, 330, 410]
          },
          {
            name: '2024/02/11',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [820, 832, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
      nouserData: [
        {no: "1", bushu1: "担当者1", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "2", bushu1: "担当者2", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "3", bushu1: "担当者3", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "4", bushu1: "担当者4", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "5", bushu1: "担当者5", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "6", bushu1: "担当者6", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46},
        {no: "7", bushu1: "担当者7", bushu2: 20, bushu3: 15, bushu4: 1, heji: 46}
      ],
      t2_show: false,
      t3_show: false,
      t32_show: false
    };
  },
  mounted() {
    this.projectTime_deploy = this.$echarts.init(this.$refs.projectTime_deploy)
    this.initChart()

    this.projectTime_day = this.$echarts.init(this.$refs.projectTime_day)
    this.initChart2()

    this.deploytime_project = this.$echarts.init(this.$refs.deploytime_project)
    this.initChart3()

    this.deploytime_day = this.$echarts.init(this.$refs.deploytime_day)
    this.initChart4()

    this.usertime_project = this.$echarts.init(this.$refs.usertime_project)
    this.initChart5()

    this.usertime_day = this.$echarts.init(this.$refs.usertime_day)
    this.initChart6()

  },
  methods: {
    showList() {
      if (this.queryMap.status == 1) {
        this.t2_show = true
        this.t3_show = false
        this.t32_show = false
      } else if (this.queryMap.status == 2) {
        this.t3_show = true
        this.t32_show = false
        this.t2_show = false
      } else if (this.queryMap.status == 3) {
        this.t3_show = false
        this.t32_show = true
        this.t2_show = false
      }
    },
    /**
     * 报表类型切换
     */
    reportTypeChange() {
      if (this.queryMap.status != 1) {
        this.picker_status = false
      } else {
        this.picker_status = true
      }
    },
    /**
     * 初始化报表
     */
    initChart() {
      const {
        projectTime_deploy,
        projectTime_deploy_option
      } = this
      projectTime_deploy.setOption(projectTime_deploy_option)
    },
    /**
     * 初始化报表
     */
    initChart2() {
      const {
        projectTime_day,
        projectTime_day_option
      } = this
      projectTime_day.setOption(projectTime_day_option)
    },

    /**
     * 初始化报表
     */
    initChart3() {
      const {
        deploytime_project,
        deploytime_project_option
      } = this
      deploytime_project.setOption(deploytime_project_option)
    },
    /**
     * 初始化报表
     */
    initChart4() {
      const {
        deploytime_day,
        deploytime_day_option
      } = this
      deploytime_day.setOption(deploytime_day_option)
    },
    /**
     * 初始化报表
     */
    initChart5() {
      const {
        usertime_project,
        usertime_project_option
      } = this
      usertime_project.setOption(usertime_project_option)
    },
    /**
     * 初始化报表
     */
    initChart6() {
      const {
        usertime_day,
        usertime_day_option
      } = this
      usertime_day.setOption(usertime_day_option)
    },

    reportInfo(report_type) {
      if (report_type === "t2") {
        this.$router.push({
          path: "/history/reportInfo_t2",
          query: {
            report_type: report_type,
            type: 1
          }
        })
      } else if (report_type === "t3") {
        this.$router.push({
          path: "/history/reportInfo_t3",
          query: {
            report_type: report_type,
            type: 1
          }
        })
      }

    },
  }
}
</script>

<style scoped>

.el-table__fixed {
  height: 100% !important;
}

</style>